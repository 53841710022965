<template>
  <div class="chart" ref="chartRef"></div>
</template>

<script setup>
import { reactive, ref, defineProps, defineExpose, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { httpPost } from "@/api/httpService.js";
import * as echarts from "echarts";
const props = defineProps({
  chartInfo: {
    type: Object,
    required: true
  }
});
let chart = reactive({});
let option = reactive({});
const chartRef = ref(null);

const setDefaultOption = () => {
  option = {
    title: [
      {
        text: props.chartInfo.title,
        left: "center"
      }
    ],
    xAxis: {
      type: "category",
      data: props.chartInfo.colNames
    },
    yAxis: {
      type: "value"
    },
    series: [
      {
        data: props.chartInfo.data,
        type: "line"
      }
    ]
  };
};
const initChartView = () => {
  nextTick(() => {
    if (!chart.id) {
      // chart = echarts.init(ctx.$el);
      chart = echarts.init(chartRef.value);
    }
    setDefaultOption();
    chart.setOption(option);
    nextTick(() => {
      chart.resize();
    });
  });
};
const setChartView = () => {
  nextTick(() => {
    // chart = echarts.init(ctx.$el);
    chart = echarts.init(chartRef.value);

    setDefaultOption();
    chart.setOption(option);
    nextTick(() => {
      chart.resize();
    });
  });
};
const exportChart = (type) => {
  let imgUrl = chart.getDataURL({
    type: type,
    backgroundColor: "#fff",
    pixelRatio: 2
  });
  var title = option.title[0].text;
  var name =
    title == "" || title == null || title == undefined
      ? new Date().getTime()
      : title;
  if (window.navigator.msSaveOrOpenBlob) {
    var bstr = atob(imgUrl.split(",")[1]);
    var n = bstr.length;
    var u8arr = new Blob([u8arr]);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    if (type == "jpeg" || type == "jpg") {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".jpg");
    } else {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".png");
    }
  } else {
    const a = document.createElement("a");
    a.href = imgUrl;
    if (type == "jpeg" || type == "jpg") {
      a.setAttribute("download", name + ".jpg");
    } else {
      a.setAttribute("download", name + ".png");
    }
    a.click();
  }
};
defineExpose({
  initChartView,
  setChartView,
  exportChart
});
</script>

<style lang="scss" scoped>
.chart {
  // width: 800px;
  height: 400px;
}
</style>
